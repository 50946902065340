(function ($, root, undefined) {

    $(function () {

        'use strict';
        // DOM ready, take it away        
        initSearch();
        
        function initSearch() {
            var html = $('html');
            var body = $('body');
            var searchContainer = $('#search-container');
            var searchContent = $('#search-content');
            var clone = $('#search-input-clone');
            var retour = $('.id-retour');
            
            var search = $('.search-trigger');
            
            search.on('keydown', function(e) {
                e.preventDefault();
                return false;
            });
            search.on('click, focus', function() {
                var scrollDistance = $(window).scrollTop();
                body.css("top", scrollDistance * -1);
                html.addClass('is-fixed');
                var searchInput = $(this).closest('.search-input');
                
                if (searchInput.hasClass('from-header')) {
                    searchContainer.addClass('is-fromHeader');
                }
                
                if ($(window).width() < 992) {                    
                    var margin = 70;
                } else {
                    var margin = 0;
                    var newWidth = 600;
                }
                var width = searchInput.innerWidth();
                var top = searchInput.offset().top - $(window).scrollTop() - margin;
                var left = searchInput.offset().left + width / 2;
                clone.css('top', top);
                clone.css('left', left);
                clone.css('width', width);

                // utilisation de animate pour avoir un callback;
                clone.animate({width: newWidth, top: top, left: left}, 0, function(){
                    searchContainer.addClass('is-open');
                    searchContainer.addClass('is-visible');

                   clone.css('top', '');
                   clone.css('left', '');

                    setTimeout(function() {
                        clone.find('input[type="search"]').focus(); // mis 2 fois volontairement
                    }, 50);
                    setTimeout(function() {
                        searchContainer.addClass('is-overflowed');
                        clone.addClass('is-ready');
                        clone.find('input[type="search"]').focus();
                    }, 350);

                    searchInput.addClass('is-hidden');
                });
                
                // fix accessibilité
                if ($('.search_slide .search-input').length) {
                    var focusTarget = $('.bloc-icones a');
                } else if ($('body').hasClass('search-results')) {
                    var focusTarget = $('#search-filters button');
                } else {
                    var focusTarget = $('.header .nav-content button');
                }
                focusTarget.on('focus', function(e) {
                    closeMenu(true, top, left, width, scrollDistance, searchInput);
                    $(document).off('mousedown');
                    focusTarget.off('focus');
                });
                
                var closeTarget = $('.search-wrapper');
                $(document).on('mousedown', function(e) {
                    if (!closeTarget.is(e.target) && closeTarget.has(e.target).length === 0) {
                        closeMenu(true, top, left, width, scrollDistance, searchInput);
                        $(document).off('mousedown');
                        focusTarget.off('focus');
                    }
                });
                
                retour.off('click');
                retour.on('click', function(e) {
                    e.preventDefault();
                    closeMenu(true, top, left, width, scrollDistance, searchInput, true);
                });
            });
            
            
            /**********************************/
            
            var searchBtn = $('#search-btn');

            searchBtn.on('click', function(e) {
                e.preventDefault();
                
                var scrollDistance = $(window).scrollTop();
                body.css("top", scrollDistance * -1);
                html.addClass('is-fixed');
                
                searchContainer.addClass('is-fromBtn');
                searchBtn.addClass('is-hidden');
                searchContainer.addClass('is-open');
                searchContainer.addClass('is-visible');

                setTimeout(function() {
                    searchContainer.addClass('is-overflowed');
                    clone.addClass('is-ready');

                    setTimeout(function() {
                        clone.find('input[type="search"]').focus();
                    }, 300);
                }, 350);
                
                // fix accessibilité
                if ($('.search_slide .search-input').length) {
                    var focusTarget = $('.bloc-icones a');
                } else if ($('body').hasClass('search-results')) {
                    var focusTarget = $('#search-filters button');
                } else {
                    var focusTarget = $('.header .nav-content button');
                }
                focusTarget.on('focus', function(e) {
                    closeMenu(true, top, null, null, scrollDistance);
                    $(document).off('mousedown');
                    focusTarget.off('focus');
                });
                
                var closeTarget = $('.search-wrapper');
                $(document).on('mousedown', function(e) {
                    if (!closeTarget.is(e.target) && closeTarget.has(e.target).length === 0) {
                        closeMenu(true, top, null, null, scrollDistance);
                        $(document).off('mousedown');
                        focusTarget.off('focus');
                    }
                });
                
                retour.off('click');
                retour.on('click', function(e) {
                    e.preventDefault();
                    closeMenu(false, top, null, null, scrollDistance, null, true);
                });
            });
            
            /**********************************/
            function closeMenu(fromSearch, top, left, width, scrollDistance, searchInput, fromMobile) {
                $(document).off('mousedown');
                searchContent.addClass('is-hidden');
                searchBtn.removeClass('is-hidden');
                searchContainer.removeClass('is-visible');
                searchContainer.removeClass('is-overflowed');
                searchContainer.removeClass('show-mask');
                $('.search-delete').removeClass('is-active');
                clone.css('top', top);
                if (fromSearch) {
                    clone.css('left', left);
                    clone.css('width', width);
                }
                clone.removeClass('is-ready');
                
                // pour site cinema
                $('header .nav .languages').removeClass('is-hidden');

                setTimeout(function() {
                    searchContainer.removeClass('is-open');
                    searchContainer.removeClass('is-fromBtn');
                    if (fromSearch) {
                        searchInput.removeClass('is-hidden');
                        searchContainer.removeClass('is-fromHeader');
                    }
                    searchContent.empty().removeClass('is-hidden').removeClass('is-visible');
                    $('#search-clone').val('');
                    html.removeClass('is-fixed');
                    body.css("top", "");
                    if (fromMobile) {
                        $('.btn-scroll').first().focus();
                    }
                    $(window).scrollTop(scrollDistance);
                    clone.removeAttr('style');
                    
                }, 500);
            }
            
            /**********************************/
            var searchClone = $('#search-clone');
            var content = $('#search-content')
            var searchDelete = $('.search-delete')
            
            searchDelete.on('click', function(e) {
                e.preventDefault();
                searchClone.val('');
                searchDelete.removeClass('is-active');
                searchContent.slideUp(300, function() {
                    searchContent.empty();
                    searchContent.removeClass('is-visible');
                    searchContainer.removeClass('show-mask');
                    searchContent.show();
                });
            });
            
            searchClone.on('input', throttle(initQuery, 800, false, true));
            
            function initQuery() {
                var query = searchClone.val();
                
                if (query.length > 1) {                    
                    var device = 'desktop';

                    if ($(window).width() < 992) {
                        device = 'mobile';
                    }

                    if (query !== '') {
                        searchDelete.addClass('is-active');
                    } else {
                        searchDelete.removeClass('is-active');
                    }
                    
                    var permalinkPrefixInput = $('input[name="permalink_prefix"]');
                    if (permalinkPrefixInput.length) {                        
                        var permalinkPrefix = permalinkPrefixInput.attr('value');
                    }

                    jQuery.ajax({
                        type : 'post',
                        url : ajaxsearch.ajaxurl,
                        data : {
                            action : 'load_ajax_results',
                            query : query,
                            device : device,
                            permalink_prefix : permalinkPrefix
                        },
                        beforeSend: function() {
                            content.addClass('loading');
                        },
                        success : function( response ) {
                            content.removeClass('loading');
                            content.html(response);
                            if ($(window).width() >= 992) {
                                searchContainer.addClass('show-mask');
                            }
                            var title = $('#search-container .search-content .title h3');
                            var type = $('#search-container .search-content .post-type .type');

                            title.each(function() {
                                $clamp($(this)[0], {clamp: 2});
                            });
                            type.each(function() {                            
                                $clamp($(this)[0], {clamp: 1});
                            });

                            setTimeout(function() {                            
                                content.addClass('is-visible');                            
                            }, 100);
                        }
                    });
                }
            }
        }
        
        // Throttle
        function throttle(func, wait, leading, trailing, context) {
            var ctx, args, result;
            var timeout = null;
            var previous = 0;
            var later = function() {
                previous = new Date;
                timeout = null;
                result = func.apply(ctx, args);
            };
            return function() {
                var now = new Date;
                if (!previous && !leading) previous = now;
                var remaining = wait - (now - previous);
                ctx = context || this;
                args = arguments;
                if (remaining <= 0) {
                    clearTimeout(timeout);
                    timeout = null;
                    previous = now;
                    result = func.apply(ctx, args);
                } else if (!timeout && trailing) {
                    timeout = setTimeout(later, remaining);
                }
                return result;
            };
        };
    });

})(jQuery, this);
